import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
const lightImg = require("../assets/logos/HCLFINALS-06-02.png");

const Footer = () => {
  return (
    <footer>
      <div class="flex flex-col bg-floral py-6 justify-center font-display">
        <div class="flex justify-center">
          <img src={lightImg} alt="HCL Logo" class="w-80" />
        </div>
        <div class="flex flex-row pt-5">
          <div class="md:w-2/6" />
          <div class="w-6/12 md:w-1/6 flex justify-center">
            <a href={instaURL}>
              <FontAwesomeIcon class="w-8" icon={faInstagram} />
            </a>
          </div>
          <div class="w-6/12 md:w-1/6 flex justify-center">
            <a href={fbURL}>
              <FontAwesomeIcon class="w-8" icon={faFacebook} />
            </a>
          </div>
          <div class="md:w-2/6" />
        </div>
        <div class="pt-5">
          <p>© 2022 Hill Country Letters, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;

const instaURL = "https://www.instagram.com/hillcountryletters/"
const fbURL = "https://www.facebook.com/hillcountryletters/";