import React from "react";
const background = require("../assets/background/background2.jpg");
const logo = require("../assets/logos/main_logo.png");

const LandingPage = () => {
  const handleClick = (id) => {
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    }, 250);
  }

  return (
    <div
      class="w-full h-screen bg-cover bg-no-repeat bg-center flex flex-col justify-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div class="flex justify-center p-5 lg:h-3/5">
        <img src={logo} alt="Hill Country Letters Logo" />
      </div>
      <div class="flex justify-center rounded-3xl p-5">
        <button class="rounded-but font-display bg-lgreen w-10/12 lg:w-1/3 p-1 pt-2"><p class="text-dgreen text-3xl drop-shadow-reg p-1" onClick={() => handleClick('contact-form')}>CONTACT US</p></button>
      </div>
    </div>
  );
};

export default LandingPage;
