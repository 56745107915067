import React from "react";
import logo from "../assets/logos/HCLFINALS-07.png";

const Header = () => {
  return (
    <div class="invisible sm-visible fixed">
      <nav>
        <ul>
          <li>
            <a href="#services">SERVICES</a>
          </li>
          <li>
            <a href="#about">ABOUT</a>
          </li>
        </ul>
      </nav>
      <div>
        <img src={logo} alt="Hill Country Letters Logo" />
      </div>
      <nav>
        <ul>
          <li>
            <a href="#social">SOCIAL</a>
          </li>
          <li>
            <a href="#contact">CONTACT</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
