import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
const img1 = require("../assets/process/IMG_4000.jpg");
const img2 = require("../assets/process/IMG_3349.jpg");
const img3 = require("../assets/process/IMG_3428.jpg");
const img4 = require("../assets/process/IMG_1445.jpg");
const img5 = require("../assets/process/IMG_2267.jpg");
const img6 = require("../assets/process/IMG_3464.jpg");
const img7 = require("../assets/process/IMG_2771.jpg");
const img8 = require("../assets/process/IMG_3539.jpg");

const Social = () => {
  return (
    <div class="py-10 bg-lgreen flex flex-col justify-center align-center">
      <div class="flex justify-center pb-10 md:pb-20 md:pt-10">
        <h1 class="font-display text-5xl text-dgreen border-b-2 border-dgreen leading-little w-4/5"><span class="bg-lgreen px-5 lg:px-10">SOCIAL</span></h1>
      </div>
      <div class="flex flex-row lg:flex-col">
        <div class="bg-red flex flex-col lg:flex-row rounded-md justify-content align-center w-1/2 ml-2 mr-1 lg:w-full lg:px-5 lg:m-0">
            <div class="mb-1 lg:mb-0 lg:my-1 lg:mr-1 lg:w-1/4">
              <img class="rounded-md" src={img1} alt="The owners - Julia and Dylan" />
            </div>
            <div class="my-1 lg:mx-1 lg:w-1/4">
              <img class="rounded-md" src={img2} alt="The owners - Julia and Dylan" />
            </div>
            <div class="my-1 lg:mx-1 lg:w-1/4">
              <img class="rounded-md" src={img3} alt="The owners - Julia and Dylan" />
            </div>
            <div class="mt-1 lg:mx-1 lg:w-1/4">
              <img class="rounded-md" src={img4} alt="The owners - Julia and Dylan" />
            </div>
        </div>
        <div class="bg-red flex flex-col lg:flex-row rounded-md justify-content align-center w-1/2 mr-2 ml-1 lg:w-full lg:px-5 lg:m-0">
            <div class="mb-1 lg:mb-0 lg:my-1 lg:mr-1 lg:w-1/4">
              <img class="rounded-md" src={img5} alt="The owners - Julia and Dylan" />
            </div>
            <div class="my-1 lg:mx-1 lg:w-1/4">
              <img class="rounded-md" src={img6} alt="The owners - Julia and Dylan" />
            </div>
            <div class="my-1 lg:mx-1 lg:w-1/4">
              <img class="rounded-md" src={img7} alt="The owners - Julia and Dylan" />
            </div>
            <div class="mt-1 lg:mx-1 lg:w-1/4">
              <img class="rounded-md" src={img8} alt="The owners - Julia and Dylan" />
            </div>
        </div>
      </div>
      <div class="pt-5">
        <p class="font-display text-dgreen text-2xl px-5 lg:text-4xl">{giveLike}
        </p>
      </div>
      <div class="pt-5">
        <div>
          <a href={instaURL}>
            <p class="font-display text-dgreen text-2xl lg:text-4xl">{insta}
              <FontAwesomeIcon icon={faInstagram} />
            </p>
          </a>
        </div>
        <div>
          <a href={fbURL}>
            <p class="font-display text-dgreen text-2xl lg:text-4xl">{fb}
              <FontAwesomeIcon icon={faFacebook} />
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Social;

const giveLike = "Give us a like or follow on Instagram or Facebook!"
const insta = "@hillcountryletters  "
const instaURL = "https://www.instagram.com/hillcountryletters/"
const fb = "Hill Country Letters  "
const fbURL = "https://www.facebook.com/hillcountryletters/"
