import React from "react";
const video = require("../assets/process/IMG_1879-decoded.mp4");

const Services = () => {
  return (
    <div class="pt-5 bg-lgreen lg:h-fit lg:pb-10">
      <div class="flex justify-center pt-10">
        <h1 class="font-display text-5xl text-dgreen border-b-2 border-dgreen leading-little w-4/5"><span class="bg-lgreen px-5 lg:px-10">SERVICES</span></h1>
      </div>
      <div class="flex flex-col lg:flex-row w-screen pt-10 justify-center content-center">
        <div class="lg:w-1/12"></div>
        <div id="border" class="rounded-t-custom border-solid border-peach mt-5 mx-5 border-6 lg:w-4/12 h-max">
          <div class=" rounded-t-custom p-4" dangerouslySetInnerHTML={{
            __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              class="rounded-t-custom"
              >
              <source src="${video}" type="video/mp4" />
              </video>`
          }}>
        </div>
        </div>
        <div class="flex flex-col justify-center content-center lg:w-6/12 my-10 mx-5 bg-peach lg:px-5">
          <p class="p-5 md:mt-5 md:mr-5 font-display text-2xl text-dgreen leading-10 lg:text-4xl">
            {serviceDescrip}
          </p>
          <p class="p-5 md:mt-5 md:mr-5 font-display text-2xl text-dgreen leading-10 lg:text-4xl">
            {prices}
          </p>
          <div class="flex flex-col justify-center content-center">
            <div class="flex flex-row py-4 font-display text-2xl text-dgreen leading-10 lg:text-4xl justify-center content-center border-solid border-dgreen border-y-6 mx-4">
              <p class="w-1/2">
                {letter}
              </p>
              <p class="w-1/2">
                {letterPrice}
              </p>
            </div>
            <div class="flex flex-row py-4 font-display text-2xl text-dgreen leading-10 lg:text-4xl justify-center content-center border-solid border-dgreen border-b-6 mx-4">
              <p class="w-1/2">
                {topper}
              </p>
              <p class="w-1/2">
                {topperPrice}
              </p>
            </div>
            {/* <div class="flex flex-row py-4 font-display text-2xl text-dgreen leading-10 lg:text-4xl justify-center content-center border-solid border-dgreen border-b-6 mx-4">
              <p class="w-1/2">
                {topper2}
              </p>
              <p class="w-1/2">
                {topper2Price}
              </p>
            </div> */}
          </div>
          <p class="text-2xl text-dgreen leading-10 lg:text-4xl font-display py-5 px-5">{note}</p>
        </div>
        <div class="w-0 lg:w-1/12"></div>
      </div>
    </div>
  );
};

export default Services;

const serviceDescrip =
  "Rent our four-foot tall rustic marquee letters for your next event and help us light up the Hill Country!";
const prices = "PRICES:";
const letter = "Four-foot Marquee Letter";
const letterPrice = "$50/day";
const topper = '"The" Topper';
const topperPrice = "$50/day";
const topper2 = '"Mr & Mrs" Topper';
const topper2Price = "$100/day";
const note = "There is an additional delivery and setup fee that is dependent on event location and quantity of letters rented. Please use the contact form below to reach out and get more information!"