import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [status, setStatus] = useState(false)

  const form = useRef(); 

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zfije0e', 'contact_form', form.current, 'rbAxeoQ6ok542oPrq')
    .then((result) => {
      console.log('Email successful');
      setStatus(true);
    }, (err) => {
      console.log(err);
    })
  }

 return (
  <div class="bg-dgreen pt-10 md:pt-20 font-display text-light">
      <div class="flex justify-center pb-5">
        <h1 class="font-display text-5xl text-light border-b-2 border-light leading-little w-4/5"><span class="bg-dgreen px-5 lg:px-10">CONTACT</span></h1>
      </div>
   <div class="flex flex-row justify-center bg-green">
    <div class="w-1/6 md:w-2/6"></div>
    <div class="flex flex-col justify-center align-center w-4/6 bg-peach rounded-but my-10 px-5 py-5">
      <h1 class="text-dgreen text-2xl lg:text-4xl">Contact Us</h1>
      <form id="contact-form" class="flex flex-col justify-center align-center rounded-but text-2xl lg:text-4xl" ref={form} onSubmit={sendEmail}>
        <input name="from_name" id="from_name" type="text" placeholder="Name" class="my-1 bg-peach p-1 placeholder-dgreen border-b-2 border-solid border-dgreen focus:outline-none focus:placeholder-floral rounded-none" />
        <input name="from_email" id="from_email" type="email" placeholder="Email" class="my-1 bg-peach p-1 placeholder-dgreen border-b-2 border-solid border-dgreen focus:outline-none focus:placeholder-floral rounded-none" />
        <textarea name="message" id="message" placeholder="Message..." rows="5" class="my-1 bg-peach p-1 mb-5 placeholder-dgreen border-2 border-solid border-dgreen focus:outline-none rounded-md focus:placeholder-floral"></textarea>
        <input class={`${status ? "bg-lgreen" : "bg-peach"} text-dgreen`} type="submit" value={`${status ? "Thank You" : "Send"}`} />
      </form>
    </div>
    <div class="w-1/6 md:w-2/6"></div>
   </div>
  </div>
 )
};

export default Contact;
